import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import { registerLicense } from "@syncfusion/ej2-base";

registerLicense(process.env.REACT_APP_LICENSE_KEY);

// Sélectionnez l'élément du DOM où vous souhaitez monter l'application
const container = document.getElementById("root");
// Créez une racine pour le conteneur
const root = createRoot(container);

// Montez l'application React dans la racine
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
