import React, { Component, useContext, useState, useEffect } from "react";
import DataProvider, { DataContext } from "./context/DataProvider";
import RoomScheduler from "./components/RoomScheduler";
import "./App.css";

class App extends Component {
  createRoot = () => {
    const root = document.createElement("div");
    root.id = "root";
    document.body.appendChild(root);
  };

  componentDidMount() {
    this.createRoot();
  }

  render() {
    return (
      <React.StrictMode>
        <DataProvider>
          <Main />
        </DataProvider>
      </React.StrictMode>
    );
  }
}

const Main = () => {
  const { user } = useContext(DataContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!user) {
      setIsModalOpen(true);
    }
  }, [user]);

  return (
    <RoomScheduler isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
  );
};

export default App;
