const API_URL = process.env.REACT_APP_API_URL;

export const authenticateUser = async (email, password) => {
  try {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const body = JSON.stringify({
      username: email,
      password: password,
    });

    const response = await fetch(`${API_URL}/auth/`, {
      method: "POST",
      headers: headers,
      body: body,
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(
        `Authentication failed: ${response.status} ${errorData.message}`
      );
    }

    const responseData = await response.json();
    return responseData.token;
  } catch (error) {
    console.error("Authentication error:", error);
    throw error;
  }
};
