import React, { useState, useEffect, useRef, useContext } from "react";
import { DataContext } from "../context/DataProvider";
import Modal from "react-modal";
import "../App.css";

import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  Day,
  Week,
  TimelineViews,
  TimelineMonth,
  Month,
  Agenda,
  Resize,
  DragAndDrop,
} from "@syncfusion/ej2-react-schedule";
import { DateTimePicker } from "@syncfusion/ej2-calendars"; // Correct import
import { DataManager, WebApiAdaptor } from "@syncfusion/ej2-data";

import SchedulerHeader from "./SchedulerHeader";
import EventEditor from "./EventEditor";
import LoginModal from "./LoginModal";

Modal.setAppElement("#root");

const RoomScheduler = ({ isModalOpen, setIsModalOpen }) => {
  const scheduleRef = useRef(null);
  const API_URL = process.env.REACT_APP_API_URL;
  const {
    user,
    spaces,
    accounts,
    config,
    token,
    filterSpace,
    filteredBookings,
    filteredSpaces,
  } = useContext(DataContext);
  const initialDate = localStorage.getItem("selectedDate")
    ? new Date(localStorage.getItem("selectedDate"))
    : new Date();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [filteredBookingData, setFilteredBookingData] = useState([]);
  const [filteredSpaceData, setFilteredSpaceData] = useState([]);
  const workHoursStart = config.AM_start;
  const workHoursEnd = config.PM_end;
  const colorOptions = [
    "#7fa900",
    "#5978ee",
    "#fec200",
    "#df5286",
    "#00bdae",
    "#865fcf",
    "#1aaa55",
    "#710193",
  ];

  useEffect(() => {
    if (Array.isArray(filteredBookings)) {
      setFilteredBookingData(
        filteredBookings.map((booking) => ({
          Id: booking.id,
          Subject: booking.formula_display,
          Description: stripHtml(booking.description),
          StartTime: new Date(booking.start_date),
          EndTime: new Date(booking.end_date),
          Service: booking.service_display,
          Price: booking.price,
          RentingPrice: booking.renting_price,
          Monthly: booking.monthly,
          Months: booking.months,
          Status: booking.status,
          RoomId: booking.space,
          Email: accounts.find((account) => account.id === booking.account)
            ?.email,
          FirstName: accounts.find((account) => account.id === booking.account)
            ?.first_name,
          LastName: accounts.find((account) => account.id === booking.account)
            ?.last_name,
          PhoneNumber: accounts.find(
            (account) => account.id === booking.account
          )?.phone_number,
        }))
      );
    }
  }, [filteredBookings, accounts]);

  useEffect(() => {
    if (Array.isArray(filteredSpaces)) {
      setFilteredSpaceData(
        filteredSpaces.map((space) => ({
          Id: space.id,
          text: space.name,
          id: space.id,
          color: getRandomColor(colorOptions),
          type: space.type_display,
        }))
      );
    }
  }, [filteredSpaces]);

  useEffect(() => {
    localStorage.setItem("selectedDate", selectedDate.toISOString());
  }, [selectedDate]);

  const onPopupOpen = (args) => {
    if (args.type === "Editor") {
      const deleteButton = args.element.querySelector(".e-event-delete");
      if (deleteButton) {
        deleteButton.style.display = "none";
      }
      setTimeout(() => {
        let startElement = args.element.querySelector("#StartTime");
        if (!startElement.classList.contains("e-datetimepicker")) {
          new DateTimePicker(
            {
              value: new Date(startElement.value) || new Date(),
              format: "dd/MM/yyyy HH:mm",
            },
            startElement
          );
        }

        let endElement = args.element.querySelector("#EndTime");
        if (!endElement.classList.contains("e-datetimepicker")) {
          new DateTimePicker(
            {
              value: new Date(endElement.value) || new Date(),
              format: "dd/MM/yyyy HH:mm",
            },
            endElement
          );
        }
      }, 0);
    }

    if (args.type === "QuickInfo" || args.type === "Editor") {
      setTimeout(() => {
        const popupElement = args.element;
        const viewPortHeight = 550;
        const popupRect = popupElement.getBoundingClientRect();

        if (popupRect.bottom > viewPortHeight) {
          const overflow = popupRect.bottom - viewPortHeight;
          popupElement.style.top = `${popupRect.top - overflow - 10}px`;
        }

        const subjectElement = args.element.querySelector(".e-subject");
        const contentElement = args.element.querySelector(".e-popup-content");
        const descriptionElement = args.element.querySelector(
          ".e-description-details"
        );

        if (subjectElement && descriptionElement) {
          subjectElement.innerText = `${args.data.Subject} ${args.data.Service} [${args.data.Status}]`;
          descriptionElement.innerText = args.data.Description;

          const priceElement = document.createElement("div");
          priceElement.innerText = `${
            args.data.Monthly
              ? formatPrice(args.data.RentingPrice)
              : formatPrice(args.data.Price)
          } ${
            args.data.Monthly
              ? `(${args.data.Months} mois/1er mois: ${formatPrice(
                  args.data.Price
                )})`
              : ""
          }`;
          priceElement.className = descriptionElement.className;
          priceElement.classList.add("e-popup-details", "price-icon");
          contentElement.appendChild(priceElement);

          const emailElement = document.createElement("div");
          emailElement.innerText = `${args.data.FirstName} ${args.data.LastName} ${args.data.PhoneNumber} ${args.data.Email}`;
          emailElement.className = descriptionElement.className;
          emailElement.classList.add("e-popup-details", "customer-icon");
          contentElement.appendChild(emailElement);
        }
      }, 0);
    }
  };

  const onRenderCell = (args) => {};

  const stripHtml = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const getRandomColor = (colors) => {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const formatPrice = (price) => {
    return `${parseInt(price).toLocaleString("fr-MG")}`;
  };

  const onActionComplete = (args) => {
    if (args.requestType === "eventChanged") {
      const updatedEvent = args.data[0];
      fetch(`${API_URL}/bookings/bookings/${updatedEvent.Id}/`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify({
          status: updatedEvent.Status,
          description: updatedEvent.Description,
          start_date: updatedEvent.StartTime,
          end_date: updatedEvent.EndTime,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            console.error("Failed to update event");
          }
        })
        .catch((error) => {
          console.error("Error updating event:", error);
        });
    }
  };

  const eventTemplate = (data) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        height: "100%",
        marginRight: ".5em",
      }}
    >
      <strong>
        {data.Subject} <span>&nbsp;</span>
        {data.Service} ({data.FirstName} {data.LastName})
        <span>&nbsp;[{data.Status}]</span>
      </strong>
    </div>
  );

  return (
    <div className="container">
      <SchedulerHeader setIsModalOpen={setIsModalOpen} />
      <ScheduleComponent
        ref={scheduleRef}
        height={filterSpace === "Tous" ? "auto" : "640"}
        timezone="UTC"
        selectedDate={selectedDate}
        firstDayOfWeek={1} // Set Monday as the first day of the week
        currentView="TimelineWeek"
        workHours={{ start: workHoursStart, end: workHoursEnd }}
        timeScale={{ slotCount: 1, interval: 60 }}
        group={{ enableCompactView: false, resources: ["MeetingRoom"] }}
        eventSettings={{
          dataSource: filteredBookingData,
          template: eventTemplate,
          fields: {
            subject: { name: "Subject", validation: { required: true } },
            description: { name: "Description" },
            location: { name: "Location" },
            startTime: { name: "StartTime", validation: { required: true } },
            endTime: { name: "EndTime", validation: { required: true } },
          },
        }}
        popupOpen={onPopupOpen}
        renderCell={onRenderCell}
        editorTemplate={EventEditor}
        actionComplete={onActionComplete}
        // update selectedDate state when navigating
        navigating={(args) => setSelectedDate(args.currentDate)}
      >
        <ViewsDirective>
          <ViewDirective option="Day" />
          <ViewDirective option="Week" />
          <ViewDirective option="TimelineDay" />
          <ViewDirective option="TimelineWeek" />
          <ViewDirective option="TimelineMonth" />
          <ViewDirective option="Month" />
          <ViewDirective option="Agenda" />
        </ViewsDirective>
        <ResourcesDirective>
          <ResourceDirective
            field="RoomId"
            title="Room Type"
            name="MeetingRoom"
            allowMultiple={true}
            dataSource={filteredSpaceData}
            textField="text"
            idField="id"
            colorField="color"
          />
        </ResourcesDirective>
        <Inject
          services={[
            Day,
            Week,
            TimelineViews,
            TimelineMonth,
            Month,
            Agenda,
            Resize,
            DragAndDrop,
          ]}
        />
      </ScheduleComponent>
      <LoginModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

export default RoomScheduler;
