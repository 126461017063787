import React, { useState, useContext } from "react";
import Modal from "react-modal";
import { DataContext } from "../context/DataProvider";

const LoginModal = ({ isModalOpen, setIsModalOpen }) => {
  const { login } = useContext(DataContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async () => {
    await login(email, password);
    setPassword(""); // Effacer le champ de saisie du mot de passe
    setIsModalOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
      contentLabel="Login"
      style={{
        content: {
          width: "300px",
          height: "fit-content",
          margin: "auto",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },
      }}
    >
      <h2>Login</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        style={{ marginBottom: "10px", width: "100%" }}
      />
      <input
        type="password"
        placeholder="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        style={{ marginBottom: "10px", width: "100%" }}
      />
      <button
        onClick={handleLogin}
        style={{ marginBottom: "10px", width: "100%" }}
      >
        Login
      </button>
      <button onClick={() => setIsModalOpen(false)} style={{ width: "100%" }}>
        Cancel
      </button>
    </Modal>
  );
};

export default LoginModal;
