import React, { createContext, useEffect, useState } from "react";
import { authenticateUser } from "../context/authServices";

const API_URL = process.env.REACT_APP_API_URL;

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const [spaces, setSpaces] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [accounts, setAccounts] = useState(
    JSON.parse(localStorage.getItem("accounts")) || []
  );
  const [config, setConfig] = useState([]);
  const [token, setToken] = useState(localStorage.getItem("token") || "");
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || null
  );
  const [filterUser, setFilterUser] = useState(
    localStorage.getItem("filter-user") || "Tous"
  );
  const [filteredBookings, setFilteredBookings] = useState(
    JSON.parse(localStorage.getItem("filtered-bookings")) || []
  );
  const [filterSpace, setFilterSpace] = useState(
    localStorage.getItem("filter-space") || "Tous"
  );
  const [filteredSpaces, setFilteredSpaces] = useState(
    JSON.parse(localStorage.getItem("filtered-spaces")) || []
  );

  const [isLoadingSpaces, setIsLoadingSpaces] = useState(false);
  const [isLoadingBookings, setIsLoadingBookings] = useState(false);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);
  const [isLoadingConfig, setIsLoadingConfig] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchSpaces();
    fetchBookings();
    fetchConfig();
  }, []);

  useEffect(() => {
    if (user) {
      updateFilteredBookings();
    }
  }, [user, filterUser, filterSpace, bookings]);

  useEffect(() => {
    updateFilteredSpaces();
  }, [filterSpace]);

  const fetchSpaces = async () => {
    setIsLoadingSpaces(true);
    try {
      const response = await fetch(`${API_URL}/spaces/spaces/`);
      if (!response.ok) throw new Error("Failed to load spaces");
      const data = await response.json();
      setSpaces(data);
      updateFilteredSpaces(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoadingSpaces(false);
    }
  };

  const fetchBookings = async () => {
    setIsLoadingBookings(true);
    try {
      const response = await fetch(`${API_URL}/bookings/bookings/`);
      if (!response.ok) throw new Error("Failed to load bookings");
      const data = await response.json();
      setBookings(data);
      if (user) {
        updateFilteredBookings(data);
      }
    } catch (error) {
      setError(error);
    } finally {
      setIsLoadingBookings(false);
    }
  };

  const updateFilteredBookings = (bookingsData = bookings) => {
    if (!user) return;

    const filtered =
      user?.is_staff && filterUser !== "Tous"
        ? bookingsData.filter(
            (booking) =>
              booking.account ===
              accounts.find((account) => account.email === filterUser)?.id
          )
        : user?.is_staff
        ? bookingsData
        : bookingsData.filter((booking) => booking.account === user.id);

    setFilteredBookings(filtered);
    localStorage.setItem("filter-user", filterUser);
    localStorage.setItem("filtered-bookings", JSON.stringify(filtered));
  };

  const updateFilteredSpaces = (spacesData = spaces) => {
    const filtered =
      filterSpace !== "Tous"
        ? spacesData.filter((space) => space.name === filterSpace)
        : spacesData;

    setFilteredSpaces(filtered);
    localStorage.setItem("filter-space", filterSpace);
    localStorage.setItem("filtered-spaces", JSON.stringify(filtered));
  };

  const fetchConfig = async () => {
    setIsLoadingConfig(true);
    try {
      const response = await fetch(`${API_URL}/bookings/config/`);
      if (!response.ok) throw new Error("Failed to load config");
      const data = await response.json();
      setConfig(data);
    } catch (error) {
      setError(error);
    } finally {
      setIsLoadingConfig(false);
    }
  };

  const login = async (email, password) => {
    setIsLoadingAccounts(true);
    try {
      const userToken = await authenticateUser(email, password);
      const headers = new Headers();
      headers.append("Authorization", `Token ${userToken}`);
      const response = await fetch(`${API_URL}/accounts/accounts/`, {
        headers,
      });
      if (!response.ok) throw new Error("Failed to authenticate user");
      const accountsData = await response.json();
      const authenticatedUser = accountsData.find((acc) => acc.email === email);
      setUser(authenticatedUser);
      setToken(userToken);
      setAccounts(accountsData);
      localStorage.setItem("user", JSON.stringify(authenticatedUser));
      localStorage.setItem("token", userToken);
      localStorage.setItem("accounts", JSON.stringify(accountsData));
    } catch (error) {
      setError(error);
    } finally {
      setIsLoadingAccounts(false);
    }
  };

  const logout = () => {
    setUser(null);
    setToken("");
    setFilteredBookings([]);
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("accounts");
    localStorage.removeItem("filter-user");
    localStorage.removeItem("filtered-bookings");
    localStorage.removeItem("filter-space");
    localStorage.removeItem("filtered-spaces");
  };

  return (
    <DataContext.Provider
      value={{
        spaces,
        bookings,
        accounts,
        config,
        token,
        user,
        login,
        logout,
        filterUser,
        filterSpace,
        setFilterUser,
        filterSpace,
        setFilterSpace,
        filteredSpaces,
        filteredBookings,
        isLoadingSpaces,
        isLoadingBookings,
        isLoadingAccounts,
        isLoadingConfig,
        error,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
