import React from "react";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";

const formatDate = (date) => {
  const d = new Date(date);
  const day = String(d.getDate()).padStart(2, "0");
  const month = String(d.getMonth() + 1).padStart(2, "0");
  const year = d.getFullYear();
  const hours = String(d.getHours()).padStart(2, "0");
  const minutes = String(d.getMinutes()).padStart(2, "0");

  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const EventEditor = (props) => {
  const startDate = props.StartTime ? formatDate(props.StartTime) : "";
  const endDate = props.EndTime ? formatDate(props.EndTime) : "";

  return props !== undefined ? (
    <div className="custom-event-editor">
      <table className="custom-event-editor-table">
        <tbody>
          <tr>
            <td className="e-textlabel">Abonnement</td>
            <td>
              <input
                id="Subject"
                className="e-field e-input"
                type="text"
                name="Subject"
                defaultValue={props.Subject || ""}
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Description</td>
            <td>
              <textarea
                id="Description"
                className="e-field e-input"
                name="Description"
                defaultValue={props.Description || ""}
              ></textarea>
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Start</td>
            <td>
              <input
                id="StartTime"
                className="e-field"
                type="text"
                name="StartTime"
                defaultValue={startDate}
                placeholder="jj/mm/aaaa hh:mm"
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">End</td>
            <td>
              <input
                id="EndTime"
                className="e-field"
                type="text"
                name="EndTime"
                defaultValue={endDate}
                placeholder="jj/mm/aaaa hh:mm"
              />
            </td>
          </tr>
          <tr>
            <td className="e-textlabel">Statut</td>
            <td>
              <select
                id="Status"
                className="e-field e-input"
                name="Status"
                defaultValue={props.Status || "Select"}
              >
                <option value="confirmed">Confirmée</option>
                <option value="pending">En attente</option>
                <option value="cancelled">Annulée</option>
              </select>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <div></div>
  );
};

export default EventEditor;
