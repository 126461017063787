import React, { useContext } from "react";
import { DataContext } from "../context/DataProvider";

const SchedulerHeader = ({ setIsModalOpen }) => {
  const {
    user,
    logout,
    filterUser,
    setFilterUser,
    filterSpace,
    setFilterSpace,
    accounts,
    spaces,
  } = useContext(DataContext);

  return (
    <div
      className="scheduler-header"
      style={{ paddingLeft: "0.8rem", paddingRight: "0.8rem" }}
    >
      <span className="left-side toolbar-text" style={{ fontSize: "0.9em" }}>
        Espaces &nbsp;
        <select
          onChange={(e) => setFilterSpace(e.target.value)}
          value={filterSpace}
        >
          <option value="Tous">Tous</option>
          {spaces.map((space) => (
            <option key={space.id} value={space.name}>
              {space.name}
            </option>
          ))}
        </select>
      </span>
      {user ? (
        <>
          {user.is_staff && (
            <span
              className="left-side toolbar-text"
              style={{ fontSize: "0.9em" }}
            >
              Utilisateurs &nbsp;
              <select
                onChange={(e) => setFilterUser(e.target.value)}
                value={filterUser}
              >
                <option value="Tous">Tous</option>
                {accounts.map((acc) => (
                  <option key={acc.email} value={acc.email}>
                    {acc.email}
                  </option>
                ))}
              </select>
            </span>
          )}
          <button className="right-side" onClick={logout}>
            Déconnexion
          </button>
          <span
            className="right-side toolbar-text"
            style={{ fontSize: "0.9em" }}
          >
            Bienvenue {user.first_name} {user.last_name} (
            {user.is_staff ? "Admin" : "Utilisateur"})
          </span>
        </>
      ) : (
        <button className="right-side" onClick={() => setIsModalOpen(true)}>
          Connexion
        </button>
      )}
    </div>
  );
};

export default SchedulerHeader;
